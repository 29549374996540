import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

type Props = { isSubmitting: boolean; title: string };

const SmallButton = ({ isSubmitting, title }: Props) => {
  return (
    <div className="mt-[20px] flex justify-end">
      <button
        type="submit"
        disabled={isSubmitting}
        className={` text-white px-[35px] py-[10px] rounded-[100px] ${
          isSubmitting ? "bg-green/50" : "bg-green"
        }`}
      >
        {isSubmitting ? (
          <LoadingOutlined style={{ fontSize: 16, color: "#fff" }} spin />
        ) : (
          title
        )}
      </button>
    </div>
  );
};

export default SmallButton;
