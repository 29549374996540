import { useMutation } from "react-query";
import { useMemo } from "react";
import { message } from "antd";
import { queryClient } from "../../../services/queryClient";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";

export const useMarkLoanAsOverdue = () => {
  const token = useMemo(() => getToken(), []);

  return useMutation(
    (id: string) =>
      axiosInstance
        .patch(
          `/loans/${id}/mark-overdue`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: any) => res.data),
    {
      onSuccess: (data: any) => {
        message.success(data?.message);
        queryClient.invalidateQueries(["useGetLoan"]);
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};
