import { useMutation } from "react-query";
import { message } from "antd";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";
import { apiConfig } from "../../../apiConfig";
import { queryClient } from "../../../services/queryClient";

export const useAddDepositEntry = (id: any) => {
  const token = getToken();

  interface Split {
    tag: string;
    amount: number;
    paymentMethod: string;
    bank: string;
    transactionDate: string;
    entryReceipt: string;
    accountNumber: string;
  }

  interface Payload {
    splits: Split[];
  }

  return useMutation(
    (payload: any) =>
      axiosInstance
        .post(`${apiConfig.ENTRIES}/${id}/deposit`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries("useGetEntriesQuery", {
          refetchActive: true,
        });

        message.success(data?.message);
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};
