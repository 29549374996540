import { useState } from "react";
import { Divider, Drawer, Empty, Modal, Table } from "antd";

import AppLayout from "../AppLayout";
import { useGetMemberQuery } from "../../../hooks/api/Members/useGetMembersQuery";
import "react-international-phone/style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../common/Loader";
import LoanApplication from "./MemberActions/LoanApplication";
import Withdrawal from "./MemberActions/Withdrawal";
import Moment from "react-moment";
import UserInfoLoader from "./Loaders/UserInfoLoader";
import Back from "../../../common/Back";
import MemberDeposits from "./MemberActions/MemberDeposits";
import AddNextofKinForm from "./Forms/AddNextofKinForm";
import NextofKinPreview from "./Forms/NextofKinPreview";
import MemberDetailsPreview from "./Forms/MemberDetailsPreview";
import { useDeleteUser } from "../../../hooks/api/Members/useDeleteUser";
import KoboConverter from "../../../common/KoboConverter";
import { useGetEntriesQuery } from "../../../hooks/api/Entries/useGetEntriesQuery";
import { useGetMemberEntries } from "../../../hooks/api/Entries/useGetMemberEntries";

const MemberDetails = () => {
  const { id } = useParams();
  const { data: memberEntries } = useGetMemberEntries({ id });
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState(false);
  const { data, isFetching, isError } = useGetMemberQuery({ id });
  const [open, setOpen] = useState({ title: "", status: false, subTitle: "" });
  const { data: entries, isFetching: isFetchingEntries } = useGetEntriesQuery({
    search: "",
    tag: "",
    isDeleted: false,
    startDate: "",
    endDate: "",
  });

  const { mutateAsync } = useDeleteUser();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    mutateAsync(`${id}`, { onSuccess: () => setModal(false) });
  };

  const navigate = useNavigate();

  const getTagLabel = (tag: string) => {
    switch (tag) {
      case "repayment":
        return "Repayment";
      case "shares":
        return "Shares";
      case "savings":
        return "Savings";
      case "special_savings":
        return "Special Savings";
      case "monthly_dues":
        return "Monthly Dues";
      case "other_savings":
        return "Other Savings";
      case "withdrawal":
        return "Withdrawal";
      case "development":
        return "Development";
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      render: (tag: string) => <>{getTagLabel(tag)}</>,
    },
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
      render: (text: string) => text || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: string) => (
        <>
          <KoboConverter amount={amount} />
        </>
      ),
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
        </div>
      ),
    },
    {
      title: "Recorded Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
        </div>
      ),
    },
  ];

  const entrydata = entries?.data?.docs.map((entry: any) => ({
    key: entry.id,
    tag: entry.tag,
    bank: entry.bank,
    amount: entry.amount,
    paymentMethod: entry.paymentMethod,
    transactionDate: entry.transactionDate,
    createdAt: entry.createdAt,
  }));

  if (isFetching) {
    return (
      <AppLayout>
        <div className="px-4 mt-5">
          <Back />
          <div className="mt-10 text-center">
            <Loader />
          </div>
        </div>
      </AppLayout>
    );
  }

  if (isError || !data) {
    return (
      <AppLayout>
        <div className="px-4 mt-5">
          <Back />
          <div className="mt-10 text-center">
            <p className="text-red-500">
              Failed to loan details. Please try again later.
            </p>
          </div>
        </div>
      </AppLayout>
    );
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const paginationConfig = {
    total: entries?.data?.meta?.totalDocs || 0,
    current: entries?.data?.meta?.page || 1,
    pageSize: entries?.data?.meta?.limit || 10,
    onChange: handlePageChange,
  };

  return (
    <AppLayout
      title="Member Management"
      crumb={`${data?.data?.firstName + " " + data?.data?.lastName}`}
    >
      <div className="flex justify-between items-center">
        <Back />
        <button
          onClick={() => setModal(true)}
          className="bg-red-500 rounded-md p-2 text-white"
        >
          Delete User
        </button>
        <Modal
          open={modal}
          title="Delete User"
          closeIcon={false}
          className="max-w-[400px]"
          footer={null}
        >
          <p>Are you sure you want to delete this user?</p>
          <div className="flex justify-end gap-2 mt-4">
            <button
              className="px-4 py-2 bg-gray-300 rounded"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Delete
            </button>
          </div>
        </Modal>
      </div>
      <div className="flex gap-[20px]">
        <div className="w-8/12">
          <div className="flex items-center justify-between py-[10px] border-b-[1px] border-[#00000033]">
            <span className="">
              <h3 className="text-[16px] font-bold">Member Details</h3>
              <p className="text-ash text-[12px]">
                View Member Account Information
              </p>
            </span>
            <div className="flex justify-between gap-2">
              <Link
                to={`/member-management/deposit/${id}`}
                className="border-[2px] px-[10px] text-white border-green rounded-full text-[10px] font-bold bg-green"
              >
                Deposit
              </Link>
              <Link
                to={`/member-management/withdrawal/${id}`}
                className="border-[2px] px-[10px] text-white border-green rounded-full text-[10px] font-bold bg-green"
              >
                Withdraw
              </Link>
              <button className="border-[2px] px-[10px] text-white border-green rounded-full text-[10px] font-bold bg-green">
                Loan Application
              </button>
            </div>
          </div>

          <div className="grid grid-cols-4 gap-2 py-[10px] border-b-[1px] border-[#00000033]">
            <span>
              <h3>Savings Balance</h3>
              <span className="text-body-text">
                <KoboConverter amount={data?.data?.savingBalance} />{" "}
              </span>
            </span>
            <span>
              <h3>Special Savings Balance</h3>{" "}
              <span className="text-body-text">
                <KoboConverter amount={data?.data?.specialSavingBalance} />{" "}
              </span>
            </span>
            <span>
              <h3>Monthly Balance</h3>{" "}
              <span className="text-body-text">
                <KoboConverter amount={data?.data?.monthlyBalance} />{" "}
              </span>
            </span>
            <span>
              <h3>Material Building Balance</h3>{" "}
              <span className="text-body-text">
                <KoboConverter amount={data?.data?.materialBuildingBalance} />{" "}
              </span>
            </span>
            <span>
              <h3>Other Saving Balance</h3>{" "}
              <span className="text-body-text">
                <KoboConverter amount={data?.data?.otherSavingsBalance} />{" "}
              </span>
            </span>
            <span>
              <h3>Loan Balance</h3>{" "}
              <span className="text-body-text">
                <KoboConverter amount={data?.data?.loanBalance} />{" "}
              </span>
            </span>
            <span>
              <h3>Shares Balance</h3>{" "}
              <span className="text-body-text">
                <KoboConverter amount={data?.data?.sharesBalance} />{" "}
              </span>
            </span>
            <span>
              <h3>Development Balance</h3>{" "}
              <span className="text-body-text">
                <KoboConverter amount={data?.data?.developmentBalance} />{" "}
              </span>
            </span>
          </div>
          <div>
            <div className="flex justify-between items-center my-3">
              <span>
                <h3>Activities</h3>
                <p className="text-body-text">See all recent activities</p>
              </span>
              {memberEntries?.data?.docs.length > 4 && (
                <Link
                  to={`/member-management/activities/${id}`}
                  className="text-green underline"
                >
                  View Activity History
                </Link>
              )}
            </div>
            {!memberEntries?.data?.docs ||
            memberEntries?.data?.docs.length === 0 ? (
              <div className="text-center py-[20px]">
                <Empty description="No entries available" />
              </div>
            ) : (
              <Table
                columns={columns}
                dataSource={memberEntries?.data?.docs.slice(0, 5)}
                loading={isFetchingEntries}
                pagination={false}
                rowKey="id"
                onRow={(record: any) => ({
                  onClick: () => navigate(`/member-management/${record.id}`),
                })}
              />
            )}
          </div>
        </div>
        <div className="w-4/12">
          <h3 className="text-[16px] font-bold text-black mb-2">
            Member Details
          </h3>
          {isFetching ? (
            <UserInfoLoader />
          ) : (
            <MemberDetailsPreview data={data} />
          )}

          <hr />
          <h3 className="text-[16px] font-bold text-black mt-[10px] mb-2">
            Next of Kin Details
          </h3>
          {!data?.data?.nextOfKin &&
          !data?.data?.nextOfKinLastName &&
          !data?.data?.nextOfKinAddress ? (
            <AddNextofKinForm id={data?.data?.id} />
          ) : (
            <NextofKinPreview data={data?.data} />
          )}
          {isFetching && <UserInfoLoader />}
        </div>
      </div>
      <Drawer
        width={500}
        open={open?.status}
        title={
          <div className="flex justify-between items-center">
            <span>
              <h3 className="text-[16px]">{open?.title}</h3>
              {/* <p className="text-[16px] font-normal">{open?.subTitle}</p> */}
            </span>
            <button
              onClick={() => {
                setOpen({ title: "", status: false, subTitle: "" });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 text-black"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        }
        closable={false}
      >
        <div className="p-[20px] text-black">
          {open?.title === "Deposit Fund" && <MemberDeposits />}
          {open?.title === "Loan Application" && <LoanApplication />}
          {open?.title === "Withdraw" && <Withdrawal setOpen={setOpen} />}
        </div>
      </Drawer>
    </AppLayout>
  );
};

export default MemberDetails;
