import React, { ChangeEvent, FormEvent, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useUpdateMemberProfile } from "../../../../hooks/api/Members/useUpdateMemberProfile";

const AddNextofKinForm = ({ id }: { id: string }) => {
  const [payload, setPayload] = useState({
    nextOfKinFirstName: "",
    nextOfKinLastName: "",
    nextOfKinAddress: "",
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const { mutateAsync: updateMemberDetails, isLoading: isUpdating } =
    useUpdateMemberProfile(id);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    updateMemberDetails(payload);
  };

  return (
    <div className="bg-white rounded-[10px] py-[24px] px-[16px]">
      <form onSubmit={handleSubmit} className="max-w-[600px] mx-auto">
        <div className="flex gap-3">
          <div className="w-6/12 mb-3">
            <label htmlFor="nextOfKinFirstName" className="text-[#14141480]">
              First Name
            </label>
            <input
              name="nextOfKinFirstName"
              type="text"
              className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
              placeholder="Next of Kin First Name"
              disabled={isUpdating}
              value={payload?.nextOfKinFirstName}
              onChange={handleChange}
            />
          </div>
          <div className="w-6/12">
            <label htmlFor="nextOfKinLastName" className="text-[#14141480]">
              Next of Kin Last Name
            </label>
            <input
              name="nextOfKinLastName"
              type="text"
              className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
              placeholder="Next of Kin Last Name"
              disabled={isUpdating}
              value={payload?.nextOfKinLastName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="">
          <label htmlFor="nextOfKinAddress" className="text-[#14141480]">
            Address
          </label>
          <textarea
            name="nextOfKinAddress"
            className="w-full p-3 h-[150px] rounded-md border-[1px] border-[#00000033] mt-2 resize-none"
            placeholder="Next of Kin Address"
            value={payload?.nextOfKinAddress}
            onChange={handleChange}
          />
        </div>
        <div className="mt-[20px] flex justify-end">
          <button
            type="submit"
            disabled={
              !payload.nextOfKinFirstName ||
              !payload.nextOfKinLastName ||
              !payload?.nextOfKinAddress ||
              isUpdating
            }
            className="bg-green text-white px-[35px] py-[10px] rounded-[100px]"
          >
            {isUpdating ? (
              <Spin indicator={<LoadingOutlined spin />} />
            ) : (
              "Update Details"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNextofKinForm;
