import React, { useState } from "react";
import { useMutation } from "react-query";
import { uploadImage } from "./api";
// import { Modal } from "antd";
import { Payload } from "../MemberDeposits";

interface ImageUploadProps {
  splitTag: string;
  onUploadSuccess: (fileUrl: string, splitTag: string) => void;
  payload: Payload;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  onUploadSuccess,
  splitTag,
  payload,
}) => {
  const [preview, setPreview] = useState<string | null>(null);
  // const [isModalVisible, setIsModalVisible] = useState(false);

  const mutation = useMutation(uploadImage, {
    onSuccess: (data) => {
      onUploadSuccess(data, splitTag);
    },
    onError: () => {
      alert("Error uploading image");
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      mutation.mutate(file);
    }
  };

  // const handleCloseModal = () => {
  //   setIsModalVisible(false); // Close the modal
  // };

  return (
    <div>
      <div className="mb-2">
        <label className="text-[#14141480]">Upload Receipt</label>
        {!preview && (
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="mt-1 block w-full text-sm text-gray-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-blue-50 file:text-blue-700
            hover:file:bg-blue-100"
          />
        )}
      </div>

      {preview && (
        <div
          className="p-2 rounded-md border bg-ash"
          // onClick={() => setIsModalVisible(true)}
        >
          <div className="flex gap-2 items-center justify-between">
            <div className="flex justify-between items-center flex-1">
              <button>Preview Image</button>
              {preview && (
                <img
                  src={preview}
                  alt="Receipt"
                  className="h-[30px] w-[30px]"
                />
              )}
            </div>
          </div>

          {/* <Modal
            title={"Receipt Preview"}
            open={isModalVisible === true}
            footer={null}
            onCancel={handleCloseModal}
          >
            <button
              className="p-2 bg-green"
              onClick={() => {
                setIsModalVisible(false);
                alert("holla");
              }}
            >
              close {JSON.stringify(isModalVisible)}
            </button>
            {preview ? (
              <img src={preview} alt="Receipt" className="w-full" />
            ) : (
              "No image available"
            )}
          </Modal> */}
        </div>
      )}

      {mutation.isLoading && (
        <div className="text-center text-green">Uploading...</div>
      )}
    </div>
  );
};

export default ImageUpload;
