import { useQuery } from "react-query";
import { message } from "antd";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";
import { apiConfig } from "../../../apiConfig";

export const useGetMemberEntries = ({
  id,
  page = 1,
}: {
  id?: any;
  page?: any;
}) => {
  const token = getToken();

  return useQuery({
    queryKey: ["useGetMemberEntries", id, page],
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.ENTRIES}/members/${id}?page=${page}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    onSuccess: (data) => {},
    onError: (error: any) => {
      message.error(error?.response?.data?.message || "Failed to fetch data");
    },
    enabled: !!token,
  });
};
