import { Table, message } from "antd";
import { useQuery } from "react-query";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";
import { apiConfig } from "../../../apiConfig";

// Hook for fetching members
export const useGetMembersQuery = ({
  page,
  queryTag,
  search,
}: {
  search?: string;
  queryTag?: string;
  page?: number;
}) => {
  const token = getToken();

  const queryParams = new URLSearchParams();
  if (queryTag) queryParams.append("queryTag", queryTag);
  if (page) queryParams.append("page", `${page}`);
  if (search) queryParams.append("search", search);

  return useQuery({
    queryKey: ["useGetMembersQuery", page, queryTag, search],
    queryFn: async () => {
      if (!token) throw new Error("Token is not available");

      const response = await axiosInstance.get(
        `${apiConfig.MEMBERS}/?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    },
    onSuccess: (data) => {},
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message ||
        "An error occurred while fetching members";
      message.error(errorMessage);
    },
    enabled: !!token,
  });
};

export const useGetMemberQuery = ({ id }: { id: any }) => {
  const token = getToken();

  return useQuery({
    queryKey: ["useGetMemberQuery"],
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.MEMBERS}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    onSuccess: (data) => {},
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
    },
    enabled: !!token,
  });
};

export const useGetMembersSummaryQuery = () => {
  const token = getToken();

  return useQuery({
    queryKey: ["useGetMembersSummaryQuery"],
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.MEMBERS}/summary`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    onSuccess: (data) => {},
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
    },
    enabled: !!token,
  });
};
