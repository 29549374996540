import React, { ChangeEvent, useEffect, useState } from "react";
import AppLayout from "../AppLayout";
import { Divider, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Back from "../../../common/Back";
import axios from "axios";
import { getToken } from "../../../utils/getToken";
import { formatNumberWithCommas } from "../../../utils/formatNumberWithCommas";
import Card from "../../../common/Card";
import { useGetMembersQuery } from "../../../hooks/api/Members/useGetMembersQuery";
import MemberSelector from "./MemberSelector";
import { useNavigate } from "react-router-dom";
import ImagePreviewer from "../../../common/ImagePreviewer";
import Input from "../../../common/Input";
import { useAddDepositEntry } from "../../../hooks/api/Entries/useAddDepositEntry";
import DepositorSelector from "./DepositorSelector";
import DepositForm from "./DepositForm";
import { useDebounce } from "../../../hooks/useDebounce";

const selectedMemberInitialState = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
};

const initialState = {
  tag: "",
  amount: "",
  paymentMethod: "",
  transactionDate: "",
  entryReceipt: "",
  bank: "",
  accountNumber: "",
};

const AddDeposit = () => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search);

  const [selectedMember, setSelectedMember] = useState(
    selectedMemberInitialState
  );
  const navigate = useNavigate();

  const [file, setFile] = useState<File | null>(null);
  const token = getToken();

  const [payload, setPayload] = useState(initialState);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile) {
      await handleUpload(selectedFile);
    }
  };

  const handleUpload = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("files", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_COTRACKR_BASE_URL}/misc/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("File uploaded successfully!");
      setPayload((prev) => ({ ...prev, entryReceipt: response.data.data }));
      console.log(response.data);
    } catch (error) {
      message.error("File upload failed!");
      console.error(error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/,/g, "");
    setPayload((prev) => ({ ...prev, [name]: numericValue }));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = new Date(e.target.value).toISOString();
    setPayload((prev) => ({ ...prev, transactionDate: formattedDate }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload((prev) => ({ ...prev, [name]: value }));
  };

  const { data: members, isLoading: isLoadingMembers } = useGetMembersQuery({
    search: debouncedSearch,
  });

  const resetForm = () => {
    setPayload(initialState);
    setSelectedMember({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
    });
    setSearch("");
  };

  const {
    mutateAsync: addEntry,
    isLoading: isLoadingEntries,
    isSuccess,
  } = useAddDepositEntry(selectedMember?.id);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess, navigate]);

  // const handleSubmit = () => {
  //   addEntry();
  // };

  const { data, isFetching } = useGetMembersQuery({ search: "" });

  return (
    <AppLayout title="Deposit & Savings">
      {/* <p>{JSON.stringify(data?.data?.docs)}</p> */}

      <Card>
        <div className="mb-4">
          <Back />
          <h3 className="text-xl my-3">Create Deposit</h3>
          <DepositorSelector
            isLoadingMembers={isLoadingMembers}
            search={search}
            setSearch={setSearch}
            members={members}
            setSelectedCustomer={setSelectedCustomer}
            selectedCustomer={selectedCustomer}
          />
        </div>
        {selectedCustomer && (
          <>
            <Divider />
            <DepositForm selectedCustomer={selectedCustomer} />
          </>
        )}
      </Card>
    </AppLayout>
  );
};

export default AddDeposit;
