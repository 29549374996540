// import { useMutation } from 'react-query';
// import { getToken } from '../../../utils/getToken';
// import { message } from 'antd';
// import { axiosInstance } from '../../../axiosInstance';

// export const useUploadFile = () => {
//   const token = getToken();

//   return useMutation(
//     async (imageData: string | Blob) => {
//       if (!imageData) throw new Error('No file selected');

//       const formData = new FormData();

//       if (typeof imageData === 'string') {
//         const blob = await (await fetch(imageData)).blob();
//         formData.append('files', blob, 'image.png');
//       } else {
//         formData.append('files', imageData);
//       }

//       const response = await axiosInstance.post('misc/file-upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       return response.data;
//     },
//     {
//       onSuccess: (data) => {
//         // let result = data?.data?.fileUrl;
//         // setPayload({ ...payload, images: result });
//       },
//       onError: (error: any) => {
//         console.error('❌ Upload failed', error);
//         message.error(
//           error?.response?.data?.message ||
//             'Error uploading file, please try again.'
//         );
//       },
//     }
//   );
// };

// import { useMutation } from "react-query";
// import { getToken } from "../../../utils/getToken";
// import { message } from "antd";

// export const useUploadFile = () => {
//   const token = getToken();

//   return useMutation(
//     async (imageData: string | Blob) => {
//       if (!imageData) throw new Error("No file selected");

//       const formData = new FormData();

//       if (typeof imageData === "string") {
//         const blob = await (await fetch(imageData)).blob();
//         formData.append("files", blob, "image.png");
//       } else {
//         formData.append("files", imageData);
//       }

//       const response = await fetch("misc/file-upload", {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         body: formData,
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Error uploading file.");
//       }

//       const data = await response.json();
//       return data;
//     },
//     {
//       onSuccess: (data) => {
//         // Handle success
//       },
//       onError: (error: any) => {
//         console.error("❌ Upload failed", error);
//         message.error(
//           error?.message || "Error uploading file, please try again."
//         );
//       },
//     }
//   );
// };

import { useMutation } from "react-query";
import { getToken } from "../../../utils/getToken";
import { message } from "antd";
import { axiosInstance } from "../../../axiosInstance";

export const useUploadFile = () => {
  const token = getToken();

  return useMutation(
    async (file: File | string) => {
      if (!file) throw new Error("No file selected");

      const formData = new FormData();

      if (typeof file === "string") {
        const blob = await (await fetch(file)).blob();
        formData.append("files", blob, "image.png");
      } else {
        formData.append("files", file);
      }

      try {
        const response = await axiosInstance.post(
          "misc/file-upload",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        return response.data; // Axios automatically handles JSON parsing
      } catch (error: any) {
        // Handle axios error
        const errorMessage =
          error.response?.data?.message || "Error uploading file.";
        throw new Error(errorMessage);
      }
    },
    {
      onError: (error: any) => {
        console.error("❌ Upload failed", error);
        message.error(
          error?.message || "Error uploading file, please try again."
        );
      },
    }
  );
};
