import React from "react";

interface KoboConverterProps {
  amount: number | string;
}

const KoboConverter: React.FC<KoboConverterProps> = ({ amount }) => {
  const koboAmount = (+amount / 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <span>
      <span>&#x20A6;</span> {koboAmount}
    </span>
  );
};

export default KoboConverter;
