import React, { useState } from "react";
import { Drawer } from "antd";
import { useGenerateLoanQuote } from "../../../../hooks/api/Loans/useGenerateLoanQuote";
import Loader from "../../../../common/Loader";
import Moment from "react-moment";

const LoanBreakdown = ({ payload }: { payload: any }) => {
  const [visible, setVisible] = useState(false);
  const { data: quote, mutateAsync, isLoading } = useGenerateLoanQuote(payload);

  return (
    <div>
      {payload?.term &&
        payload?.interestCalculationMethod &&
        payload?.loanAmount && (
          <div className="mb-3 align-right">
            <button
              onClick={() => {
                setVisible(true);
                mutateAsync();
              }}
              className="text-green font-medium"
            >
              View Loan Breakdown
            </button>
          </div>
        )}
      <Drawer
        title="Loan Breakdown"
        open={visible}
        onClose={() => setVisible(false)}
        maskClosable={false}
        width={500}
      >
        <div className="p-[15px]">
          {isLoading ? (
            <Loader />
          ) : (
            <section className="mb-6 px-3">
              <div className="mb-4">
                {quote?.data?.totalInterest && (
                  <div className=" mb-3">
                    <p className="text-gray-500">Total Interest</p>
                    <h3 className="font-bold text-2xl">
                      ₦{quote?.data?.totalInterest.toLocaleString() || "N/A"}
                    </h3>
                  </div>
                )}
                {quote?.data?.totalRepayment && (
                  <div className="mb-3">
                    <p className="text-gray-500">Total Repayment</p>
                    <h3 className="font-bold text-2xl">
                      ₦{quote?.data?.totalRepayment.toLocaleString() || "N/A"}
                    </h3>
                  </div>
                )}
                {quote?.data?.loanDisbursed && (
                  <div className="mb-3">
                    <p className="text-gray-500">Loan Disbursed</p>
                    <div className="font-bold text-2xl">
                      ₦{quote?.data?.loanDisbursed.toLocaleString() || "N/A"}
                    </div>
                  </div>
                )}
              </div>
              <hr />
              <section className="mb-6 py-3 bg-white rounded-md">
                <h2 className="font-semibold text-[18px] text-gray-800 mb-2">
                  Breakdown
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {quote?.data?.schedule &&
                    quote?.data?.schedule.map(
                      ({
                        month,
                        monthlyPrincipal,
                        principal,
                        interest,
                        total,
                        repaymentDate,
                      }: any) => (
                        <div key={month} className="mb-6">
                          <p className="font-bold text-green">Month {month}</p>
                          <div className="font-bold text-[#14141480]">
                            {monthlyPrincipal ? (
                              <span>
                                Principal: ₦
                                {Number(
                                  monthlyPrincipal.toFixed(2)
                                ).toLocaleString()}
                              </span>
                            ) : null}
                          </div>
                          <div className="font-bold text-[#14141480]">
                            {principal ? (
                              <span>
                                Principal: ₦
                                {Number(principal.toFixed(2)).toLocaleString()}
                              </span>
                            ) : null}
                          </div>
                          <div className="text-gray-500">
                            {interest ? (
                              <span>
                                Interest: ₦
                                {Number(interest.toFixed(2)).toLocaleString()}
                              </span>
                            ) : null}
                          </div>
                          <div className="text-gray-500">
                            {repaymentDate ? (
                              <span>
                                Due Date:{" "}
                                <Moment format="DD-MM-YYYY">
                                  {repaymentDate}
                                </Moment>
                              </span>
                            ) : null}
                          </div>
                          <p className="text-gray-500">
                            Total: ₦{Number(total.toFixed(2)).toLocaleString()}
                          </p>
                        </div>
                      )
                    )}
                </div>
              </section>
            </section>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default LoanBreakdown;
