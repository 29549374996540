import React from "react";
import Input from "../../../../common/Input";

type Props = {};

const LoanCollector = ({
  collector,
  isLoadingMembers,
  search,
  setSearch,
  members,
  setCollector,
  resetForm,
}: any) => {
  return (
    <div>
      {!collector?.id ? (
        <>
          <div className="mb-3">
            <Input
              label={"Select Collector"}
              name="search"
              type={"text"}
              value={search}
              placeholder="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {isLoadingMembers && (
            <div className="py-[20px] text-center">Fetching Members...</div>
          )}
          {search.length > 1 && (
            <div className="mb-3">
              <div className="h-[150px] overflow-scroll">
                {members?.data?.docs?.length === 0 ? (
                  <div className="py-[20px] text-center">No member found</div>
                ) : (
                  members?.data?.docs?.map(
                    ({ id, firstName, lastName, email }: any) => (
                      <div
                        key={id}
                        className="p-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100"
                        onClick={() => {
                          setSearch("");
                          setCollector({
                            id,
                            firstName,
                            lastName,
                            email,
                          });
                        }}
                      >
                        {firstName} {lastName} - {email}
                      </div>
                    )
                  )
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        // Display selected collector
        <div className="flex items-center justify-between border p-3 rounded-md mb-3">
          <div>
            <p className="font-semibold">
              {collector.firstName} {collector.lastName}
            </p>
            <p className="text-sm text-gray-500">{collector.email}</p>
          </div>
          <button
            onClick={() => resetForm()}
            className="text-red-500 border border-red-500 px-3 py-1 rounded-md hover:bg-red-500 hover:text-white"
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

export default LoanCollector;
