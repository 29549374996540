import { useMutation } from "react-query";
// import axiosInstance from '../../../axiosInstance';
import { apiConfig } from "../../../apiConfig";
import { message } from "antd";
import { queryClient } from "../../../services/queryClient";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";

interface Props {
  profileImage: string;
  interestRate: string;
  societyName: string;
  memberSize: string;
}

export const useUpdateUserPreference = () => {
  const token = getToken();

  return useMutation(
    async (payload: Props) => {
      const response = await axiosInstance.patch(
        `${apiConfig.UPDATE_PROFILE}`,
        {
          ...payload,
          interestRate: +payload?.interestRate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    },
    {
      onSuccess: (data) => {
        message.success(data?.message);
        queryClient.invalidateQueries("useGetMeQuery");
      },
      onError: (error: any) => {
        message.error(error?.message);
      },
    }
  );
};
