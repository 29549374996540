import { useState, useRef } from "react";

const SearchInput = ({ search, setSearch }: any) => {
  const [inputValue, setInputValue] = useState("");
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      setSearch(value);
    }, 500);
  };

  const clearInput = () => {
    setInputValue("");
    setSearch("");
  };

  return (
    <div className="relative w-[200px]">
      <input
        className="w-full p-3 rounded-md"
        placeholder="Search"
        value={inputValue}
        onChange={handleChange}
      />
      {inputValue && (
        <button
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500"
          onClick={clearInput}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default SearchInput;
