import { Table } from "antd";
import AppLayout from "../AppLayout";
import Moment from "react-moment";
import { useEffect, useState } from "react";
import { useGetTransactions } from "../../../hooks/api/BookKeeping/useGetTransactions";
import { Link, useNavigate } from "react-router-dom";
import TableHeader from "../../../common/TableHeader";
import BookKeepingStats from "./components/BookKeepingStats";
import SearchInput from "../../../common/SearchInput";
import KoboConverter from "../../../common/KoboConverter";

const BookKeeping = () => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("expense");
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const { data, isFetching } = useGetTransactions({
    page,
    search: debouncedSearch,
    tag,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const columns = [
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      render: (text: string) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Bank",
      dataIndex: "bankName",
      key: "bankName",
      render: (text: string) => text || "N/A",
    },
    {
      title: "Recepient",
      dataIndex: "recipientName",
      key: "recipientName",
    },
    {
      title: "Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (paymentMethod: string) => (
        <span className="capitalize">{paymentMethod}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: string) => <KoboConverter amount={amount} />,
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
        </div>
      ),
    },
    {
      title: "Recorded Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
        </div>
      ),
    },
    {
      title: "Receipt",
      dataIndex: "receiptUrl",
      key: "receiptUrl",
      render: (receiptUrl: string) => (
        <a
          href={receiptUrl}
          target="_blank"
          className="text-green"
          rel="noreferrer"
        >
          Receipt
        </a>
      ),
    },
  ];

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const paginationConfig = {
    total: data?.data?.meta?.totalDocs || 0,
    current: data?.data?.meta?.page || 1,
    pageSize: data?.data?.meta?.limit || 10,
    onChange: handlePageChange,
  };

  return (
    <AppLayout title="Book Keeping">
      <BookKeepingStats />
      <TableHeader
        title="All Transactions"
        description="View transaction records"
        route="/book-keeping/create"
        linkTitle="Add Transaction"
        right={
          <div className="flex items-center gap-2">
            <div className="flex gap-2 items-center">
              <span className="text-body-text">Type:</span>
              <select
                className="p-3 rounded-md border-[1px] border-[#00000033]"
                name="tag"
                value={tag}
                onChange={(e) => {
                  setTag(e?.target?.value);
                }}
              >
                <option value="expense">Expense</option>
                <option value="income">Income</option>
                <option value="tax">Taxes</option>
              </select>
            </div>

            <SearchInput setSearch={setSearch} search={search} />
          </div>
        }
      />
      <Table
        rowKey="id"
        columns={columns}
        scroll={{ y: "60vh" }}
        dataSource={data?.data?.docs}
        className="manrope mt-[20px] w-full"
        rowClassName="cursor-pointer"
        pagination={paginationConfig}
        loading={isFetching}
        // onRow={(record: any) => ({
        //   onClick: () => navigate(`/book-keeping/${record.id}`),
        // })}
      />
    </AppLayout>
  );
};

export default BookKeeping;
