import { useQuery } from "react-query";
import { message } from "antd";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";

interface UseGetTransactionsParams {
  page?: number;
  search?: string;
  tag?: string;
}

export const useGetTransactions = ({
  page = 1,
  search = "",
  tag,
}: UseGetTransactionsParams) => {
  const token = getToken();

  return useQuery({
    queryKey: ["useGetTransactions", page, search, tag],
    queryFn: () =>
      axiosInstance
        .get(`/book-keepings`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { page, search, tag },
        })
        .then((res: any) => res.data),
    onSuccess: () => {},
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
    },
    enabled: !!token,
  });
};
