import { useQuery } from "react-query";
import { message } from "antd";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";
import { apiConfig } from "../../../apiConfig";

export const useGetEntryQuery = ({
  accountId,
  memberId,
}: {
  accountId: any;
  memberId: any;
}) => {
  const token = getToken();

  return useQuery({
    queryKey: ["useGetEntryQuery", accountId, memberId],
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.ENTRIES}/${accountId}/members/${memberId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    onSuccess: (data) => {},
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
    },
    enabled: !!token,
  });
};
