import AppLayout from "../AppLayout";
import { useGetDashboardOverviewQuery } from "../../../hooks/api/Dashboard/useGetDashboardOverviewQuery";
import { useGetEntriesQuery } from "../../../hooks/api/Entries/useGetEntriesQuery";
import { Table } from "antd";
import KoboConverter from "../../../common/KoboConverter";
import Moment from "react-moment";
import FinanceData from "./Graph";

const Overview = () => {
  const Icon = require("../../../assets/images/icon.png");
  const { data } = useGetDashboardOverviewQuery();
  const { data: entries, isLoading: isGettingEntries } = useGetEntriesQuery({});

  const getTagLabel = (tag: string) => {
    switch (tag) {
      case "repayment":
        return "Repayment";
      case "shares":
        return "Shares";
      case "savings":
        return "Savings";
      case "special_savings":
        return "Special Savings";
      case "monthly_dues":
        return "Monthly Dues";
      case "other_savings":
        return "Other Savings";
      case "withdrawal":
        return "Withdrawal";
      case "development":
        return "Development";
      default:
        return "";
    }
  };

  const entriesColumns = [
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      render: (tag: string) => <>{getTagLabel(tag)}</>,
    },
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
      render: (text: string) => text || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: string) => (
        <>
          <KoboConverter amount={amount} />
        </>
      ),
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
        </div>
      ),
    },
    {
      title: "Recorded Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
          <br />
          <Moment format="hh:mm a">{date}</Moment>
        </div>
      ),
    },
  ];
  return (
    <AppLayout title="Overview">
      <div>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-[10px]">
          <div className="card">
            <div className="flex justify-between mb-2">
              <h3 className="font-semibold">Deposit</h3>
              <span className="text-sm text-gray-500">Last 30 days</span>
            </div>
            <div className="text-2xl font-bold">
              <KoboConverter
                amount={data?.data?.totalDeposits?.monthlyBalance}
              />
            </div>
            {/* <p className="text-gray-500">vs. NGN 0 last period</p> */}
          </div>

          <div className="card">
            <div className="flex justify-between mb-2">
              <h3 className="font-semibold">Loans</h3>
              <span className="text-sm text-gray-500">Last 30 days</span>
            </div>
            <div className="text-2xl font-bold">
              <KoboConverter amount={data?.data?.totalLoans} />
            </div>
            {/* <p className="text-gray-500">vs. NGN 0 last period</p> */}
          </div>

          <div className="card">
            <div className="flex justify-between mb-2">
              <h3 className="font-semibold">Shares</h3>
              <span className="text-sm text-gray-500">Last 30 days</span>
            </div>
            <div className="text-2xl font-bold">
              {/* <KoboConverter
                amount={data?.data?.monthreportlyDeposits?.monthlyBalance}
              /> */}
              {/* {JSON.stringify(data?.data)} */}
            </div>
            {/* <p className="text-gray-500">vs. NGN 0 last period</p> */}
          </div>
        </div>
        <div className="md:flex md:flex-row flex-col mt-3 gap-4 md:mt-0 ">
          <div className="md:w-7/12 w-full">
            <div className="">
              <h3 className="font-bold">Activities</h3>
              <p className="text-gray-600">See all recent activities</p>
            </div>
            <Table
              rowKey="id"
              columns={entriesColumns}
              loading={isGettingEntries}
              className="cursor-pointer mt-4"
              dataSource={entries?.data?.docs.slice(0, 9)}
              scroll={{ y: "50vh" }}
              pagination={false}
            />
          </div>
          <div className="md:w-5/12 w-full">
            <FinanceData data={data?.data} />
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Overview;

const Indicators = () => {
  return (
    <div className="flex bg-[#E8E8E833] rounded-[10px] px-[20px] py-[11px] text-[14px] gap-[16px]">
      <span className="flex gap-[5px] items-center">
        <span className="h-[10px] w-[10px] rounded-full bg-green"></span>
        <span className="font-bold text-dark">Deposit</span>
      </span>
      <span className="flex gap-[5px] items-center">
        <span className="h-[10px] w-[10px] rounded-full bg-greenish"></span>
        <span className="font-bold text-dark">Withdrawal</span>
      </span>
      <span className="flex gap-[5px] items-center">
        <span className="h-[10px] w-[10px] rounded-full bg-[#CCF6F0]"></span>
        <span className="font-bold text-dark">Loan</span>
      </span>
    </div>
  );
};

const data = [
  {
    id: 1,
    time: "11:19 AM",
    elapsed: "10 Min",
    name: "Qudus Abdullah",
    _id: "#TID2122210",
    caption: "Member Paid their monthly Dues",
    amount: "NGN 60.00",
  },
  {
    id: 2,
    time: "11:19 AM",
    elapsed: "10 Min",
    name: "Alliyah Ahmed Shola",
    _id: "#TID2122210",
    caption: "Member Was Granted a Loan",
    amount: "NGN 60.00",
  },
  {
    id: 3,
    time: "11:19 AM",
    elapsed: "10 Min",
    name: "Bamidele Afolayan",
    _id: "#TID2122210",
    caption: "Member Made a Withdrawal",
    amount: "NGN 60.00",
  },
];
