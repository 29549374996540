import { useMutation } from "react-query";
import { apiConfig } from "../../../apiConfig";
import { message } from "antd";
import { queryClient } from "../../../services/queryClient";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";

export const useDeleteUser = () => {
  const token = getToken();

  return useMutation(
    (id: string) =>
      axiosInstance
        .patch(
          `${apiConfig.MEMBERS}/${id}/delete`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: any) => res.data),
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries("useGetMemberQuery", {
          refetchActive: true,
        });

        message.success("Member Deleted Successfully");
        // navigate();
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message || "Error occurred");
      },
    }
  );
};
