import Input from "../../../../common/Input";
import Moment from "react-moment";
import { Divider, Modal } from "antd";
import { useState } from "react";
import { useUpdateUserPreference } from "../../../../hooks/api/Profile/useUpdateUserPreference";

const SettingsTab = ({ me }: any) => {
  const user = me?.data?.user;
  const society = me?.data?.society;
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState({
    profileImage: society?.profileImage,
    societyName: society?.societyName,
    memberSize: society?.memberSize,
    interestRate: society?.interestRate,
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const { mutateAsync } = useUpdateUserPreference();

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    mutateAsync(payload, { onSuccess: () => setOpen(false) });
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <h3>Society Details</h3>
          <p className="mb-3 text-body-text">View or Update Society Details</p>
        </div>
        <button
          className="text-green rounded-full px-[20px] py-[10px] border-[1px] border-green text-[13px]"
          onClick={() => setOpen(true)}
        >
          Edit
        </button>
      </div>

      <div>
        <img
          src={society?.profileImage}
          alt=""
          className="h-[100px] w-[100px] rounded-full mb-3"
        />
        <div className="flex gap-2">
          <div className="w-6/12">
            <Input
              label={"Society Name"}
              name={"societyName"}
              type={"text"}
              value={society.societyName}
              disabled={true}
              placeholder={""}
            />
          </div>
          <div className="w-6/12">
            <Input
              label={"Interest Rate"}
              name={"email"}
              type={"text"}
              value={society.interestRate + "%"}
              disabled={true}
              placeholder={""}
            />
            <Modal
              open={open}
              title="Update Society Data"
              closeIcon={false}
              className="max-w-[600px]"
              footer={null}
            >
              <h3 className="text-[14px] mb-2 mt-4">Interest Rate</h3>
              <p className="text-body-text">
                Are you sure you want to change the loan interest rate? This
                action would affect all loan applications and ufrther loan
                calculations
              </p>
              <select
                id=""
                name="interestRate"
                value={payload.interestRate}
                onChange={handleInputChange}
                className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-3"
              >
                <option value="" disabled>
                  Select option
                </option>
                <option value="1">1%</option>
                <option value="1.5">1.5%</option>
                <option value="2">2%</option>
                <option value="2.5">2.5%</option>
                <option value="3">3%</option>
                <option value="3.5">3.5%</option>
                <option value="4">4%</option>
              </select>
              <div className="flex justify-end gap-2 mt-4">
                <button
                  className="px-4 py-2 bg-gray-300 rounded"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-green text-white rounded"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Approve
                </button>
              </div>
            </Modal>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="w-6/12">
            <Input
              label={"Member Size"}
              name={"memberSize"}
              type={"text"}
              value={payload.memberSize}
              disabled={true}
              placeholder={""}
            />
          </div>
          <div className="w-6/12">
            <div className="mb-3">
              <label
                htmlFor=""
                className="text-[#14141480] flex justify-between items-center"
              >
                Date Joined
              </label>
              <div className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2">
                <Moment format="DD-MM-YYYY">{society?.createdAt}</Moment>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="w-6/12">
            <Input
              label={"Society ID"}
              name={"societyId"}
              type={"text"}
              value={user.societyId}
              disabled={true}
              placeholder={""}
            />
          </div>
          <div className="w-6/12">
            <div className="mb-3">
              <label
                htmlFor=""
                className="text-[#14141480] flex justify-between items-center"
              >
                Email Verification
              </label>
              <div className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2">
                <span
                  className={`font-medium ${
                    user?.isEmailVerified ? "text-green" : "text-red-500"
                  }`}
                >
                  {user?.isEmailVerified ? "Verified" : "Not Verified"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      {/* <h3>User Details</h3> */}
      <div className="flex gap-2">
        <div className="w-6/12">
          <Input
            label={"Admin"}
            name={"name"}
            type={"text"}
            value={user?.firstName + " " + user?.lastName}
            disabled={true}
            placeholder={""}
          />
        </div>
        <div className="w-6/12">
          <Input
            label={"Email"}
            name={"interestRate"}
            type={"text"}
            value={user?.email}
            disabled={true}
            placeholder={""}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsTab;
