import React from "react";
import { Collapse, Card, Divider } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import KoboConverter from "../../../common/KoboConverter";

const { Panel } = Collapse;

const FinanceData = ({ data }: any) => {
  // const data = {
  //   totalDeposits: {
  //     savingBalance: 833740000,
  //     specialSavingBalance: 87500000,
  //     monthlyBalance: 50000040000000,
  //     materialBuildingBalance: 0,
  //     otherSavingsBalance: 40000000,
  //     sharesBalance: 50000,
  //   },
  //   totalLoans: 530000,
  //   yearlyDeposits: {
  //     savingBalance: 233740000,
  //     specialSavingBalance: 167500000,
  //     monthlyBalance: 40000000,
  //     materialBuildingBalance: 0,
  //     otherSavingsBalance: 40000000,
  //     sharesBalance: 50000,
  //   },
  //   monthlyDeposits: {
  //     savingBalance: 233740000,
  //     specialSavingBalance: 167500000,
  //     monthlyBalance: 40000000,
  //     materialBuildingBalance: 0,
  //     otherSavingsBalance: 40000000,
  //     sharesBalance: 50000,
  //   },
  //   monthlyTrend: [
  //     {
  //       month: 12,
  //       year: 2024,
  //       totalSavingBalance: 600000000,
  //       totalSpecialSavingBalance: -80000000,
  //       totalMonthlyBalance: 50000000000000,
  //       totalMaterialBuildingBalance: 0,
  //       totalOtherSavingsBalance: 0,
  //       totalSharesBalance: 0,
  //       totalLoanBalance: 444000,
  //     },
  //     {
  //       month: 1,
  //       year: 2025,
  //       totalSavingBalance: 233740000,
  //       totalSpecialSavingBalance: 167500000,
  //       totalMonthlyBalance: 40000000,
  //       totalMaterialBuildingBalance: 0,
  //       totalOtherSavingsBalance: 40000000,
  //       totalSharesBalance: 50000,
  //       totalLoanBalance: 86000,
  //     },
  //   ],
  // };

  const cardData = [
    { title: "Total Deposits", content: data.totalDeposits },
    { title: "Total Loans", content: { totalLoans: data.totalLoans } },
    { title: "Yearly Deposits", content: data.yearlyDeposits },
    { title: "Monthly Deposits", content: data.monthlyDeposits },
    { title: "Monthly Trend", content: data.monthlyTrend },
  ];

  const getTagLabel = (tag: string) => {
    switch (tag) {
      case "savingBalance":
        return "Saving Balance";
      case "specialSavingBalance":
        return "Special Saving Balance";
      case "monthlyBalance":
        return "Monthly Balance";
      case "materialBuildingBalance":
        return "Material Building Balance";
      case "otherSavingsBalance":
        return "Other Savings Balance";
      case "sharesBalance":
        return "Shares Balance";
      case "totalLoans":
        return "Total Loans";
      default:
        return "";
    }
  };

  const renderCardContent = (content: any) => {
    if (Array.isArray(content)) {
      return content.map((item, index) => (
        <ul key={index} className="mb-3">
          <li className="font-bold">
            Month/Year:
            {item.month}/{item.year}
          </li>
          <li>
            Total Saving Balance:{" "}
            <span className="text-gray-600">
              <KoboConverter amount={item.totalSavingBalance} />
            </span>
          </li>
          <li>
            Total Special Saving Balance:
            <span className="text-gray-600">
              <KoboConverter amount={item.totalSpecialSavingBalance} />
            </span>
          </li>
          <li>
            Total Monthly Balance:{" "}
            <span className="text-gray-600">
              <KoboConverter amount={item.totalMonthlyBalance} />
            </span>
          </li>
          <li>
            Total Material Building Balance:
            <span className="text-gray-600">
              <KoboConverter amount={item.totalMaterialBuildingBalance} />
            </span>
          </li>
          <li>
            Total Other Savings Balance:{" "}
            <span className="text-gray-600">
              <KoboConverter amount={item.totalOtherSavingsBalance} />
            </span>
          </li>
          <li>
            Total Shares Balance:{" "}
            <span className="text-gray-600">
              <KoboConverter amount={item.totalSharesBalance} />
            </span>
          </li>
          <li>
            Total Loan Balance:
            <span className="text-gray-600">
              <KoboConverter amount={item.totalLoanBalance} />
            </span>
          </li>
        </ul>
      ));
    } else {
      return (
        <ul>
          {Object.entries(content).map(([key, value], index) => (
            <li key={index}>
              <span className="text-black">{getTagLabel(key)}:</span>
              <span className="text-gray-600">
                <KoboConverter amount={String(value)} />
              </span>
            </li>
          ))}
        </ul>
      );
    }
  };

  return (
    <div className="">
      <h2 className="font-bold mb-6">Finance Data</h2>
      <div className="gap-4 h-[60vh] overflow-y-scroll">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            bordered={false}
            className="shadow-md mb-3"
          >
            <Collapse
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              className="site-collapse-custom-collapse"
            >
              <Panel
                header="View Details"
                key="1"
                className="site-collapse-custom-panel"
              >
                {renderCardContent(card.content)}
              </Panel>
            </Collapse>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default FinanceData;
