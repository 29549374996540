import AppLayout from "../AppLayout";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import Loader from "../../../common/Loader";
import KoboConverter from "../../../common/KoboConverter";
import Back from "../../../common/Back";
import { useGetEntryQuery } from "../../../hooks/api/Entries/useGetEntryQuery";

const SingleSepositDetails = () => {
  const { accountId, memberId } = useParams();
  const { data, isLoading } = useGetEntryQuery({ accountId, memberId });

  const getTagLabel = (tag: string) => {
    switch (tag) {
      case "repayment":
        return "Repayment";
      case "shares":
        return "Shares";
      case "savings":
        return "Savings";
      case "special_savings":
        return "Special Savings";
      case "monthly_dues":
        return "Monthly Dues";
      case "other_savings":
        return "Other Savings";
      case "withdrawal":
        return "Withdrawal";
      case "development":
        return "Development";
      default:
        return "";
    }
  };

  return (
    <AppLayout title="Deposit & Savings">
      {isLoading && <Loader />}

      {!isLoading && data && (
        <div>
          <Back />
          <div className="flex gap-3 mb-3">
            <div className="w-6/12 ">
              <div className="flex justify-between items-center rounded-lg p-4 bg-white">
                <span>
                  <h3 className="text-[18px] text-black font-bold">
                    Transactions Details
                  </h3>
                  <p className="text-gray-600">View transaction detail</p>
                </span>
                <span className="text-[20px]">
                  <KoboConverter amount={data?.data?.amount} />
                </span>
              </div>

              <div className="rounded-lg p-4 bg-white mt-3">
                <div className="mb-4">
                  <strong>Tag:</strong>{" "}
                  <span className="bg-[#3d3d3d] rounded-md p-2 text-white">
                    {getTagLabel(data?.data?.tag)}
                  </span>
                </div>

                <h2 className="text-[18px] font-bold mb-2 pt-5">
                  Member Details
                </h2>

                <div className="mb-4">
                  <strong>Name:</strong>{" "}
                  {data?.data?.member?.firstName || "N/A"}{" "}
                  <span className="text-body-text">
                    {data?.data?.member?.lastName || ""}
                  </span>
                </div>

                <div className="mb-4">
                  <strong>Email:</strong>
                  <span className="text-body-text">
                    {data?.data?.member?.email || "N/A"}
                  </span>
                </div>

                <div className="mb-4">
                  <strong>Phone Number:</strong>{" "}
                  <span className="text-body-text">
                    {data?.data?.member?.phoneNumber || "N/A"}
                  </span>
                </div>

                <div className="mb-4">
                  <strong>Address:</strong>
                  <span className="text-body-text">
                    {data?.data?.member?.address || "N/A"},{" "}
                  </span>
                </div>

                <div className="mb-4">
                  <strong>Shares Balance:</strong>
                  {data?.data?.member?.sharesBalance ? (
                    <KoboConverter amount={data?.data?.member?.sharesBalance} />
                  ) : (
                    "N/A"
                  )}
                </div>

                <div className="mb-4">
                  <strong>Saving Balance:</strong>
                  {data?.data?.member?.savingBalance ? (
                    <KoboConverter amount={data?.data?.member?.savingBalance} />
                  ) : (
                    "N/A"
                  )}
                </div>

                <div className="mb-4">
                  <strong>Special Saving Balance:</strong>
                  {data?.data?.member?.specialSavingBalance ? (
                    <KoboConverter
                      amount={data?.data?.member?.specialSavingBalance}
                    />
                  ) : (
                    "N/A"
                  )}
                </div>

                <div className="mb-4">
                  <strong>Date of Birth:</strong>{" "}
                  {data?.data?.member?.dateOfBirth ? (
                    <Moment format="DD-MM-YY" className="text-body-text">
                      {data?.data?.member?.dateOfBirth}
                    </Moment>
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>
            </div>
            <div className="w-4/12 ">
              <div className="rounded-lg p-4 bg-white">
                <h3 className="text-[18px] text-black">Payment Details</h3>
                <p className="text-gray-600">View transaction detail</p>

                <div className="mb-2 mt-6">
                  <strong>Payment Method:</strong>{" "}
                  <span className="text-body-text">
                    {data?.data?.paymentMethod || "N/A"}
                  </span>
                </div>
                <div className="mb-2">
                  <strong>Account Number:</strong>{" "}
                  <span className="text-body-text">
                    {data?.data?.accountNumber || "N/A"}
                  </span>
                </div>
                <div className="mb-2">
                  <strong>Bank:</strong>{" "}
                  <span className="text-body-text">
                    {data?.data?.bank || "N/A"}
                  </span>
                </div>
                <div className="mb-2">
                  <strong>Transaction Date:</strong>{" "}
                  <span className="text-body-text">
                    {data?.data?.transactionDate ? (
                      <Moment format="DD-MM-YYYY">
                        {data?.data?.transactionDate}
                      </Moment>
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>
                <a
                  href={data?.data?.entryReceipt}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green w-full rounded-lg text-white p-3 block text-center"
                >
                  View Receipt
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default SingleSepositDetails;
