import { axiosInstance } from "../../../axiosInstance";

export const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append("files", file);

  const response = await axiosInstance.post("/misc/file-upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};
