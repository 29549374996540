import { useQuery } from "react-query";
import { message } from "antd";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";

export const useGetTransactionsStats = () => {
  const token = getToken();

  return useQuery({
    queryKey: ["useGetTransactionsStats"],
    queryFn: () =>
      axiosInstance
        .get("/book-keepings/stats", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    onSuccess: () => {},
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
    },
    enabled: !!token,
  });
};
