import React, { ChangeEvent, FormEvent, useState } from "react";
import Moment from "react-moment";
import { useUpdateMemberProfile } from "../../../../hooks/api/Members/useUpdateMemberProfile";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const NextofKinPreview = ({ data }: any) => {
  const [edit, setEdit] = useState(false);
  const [payload, setPayload] = useState({
    nextOfKinFirstName: data?.nextOfKinFirstName,
    nextOfKinLastName: data?.nextOfKinLastName,
    nextOfKinAddress: data?.nextOfKinAddress,
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const { mutateAsync: updateMemberDetails, isLoading: isUpdating } =
    useUpdateMemberProfile(data?.id);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    updateMemberDetails(payload, {
      onSuccess: () => {
        setEdit(false);
      },
    });
  };

  return (
    <div>
      {!edit ? (
        <div>
          <div className="flex items-center gap-2 mb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
              />
            </svg>
            {data?.nextOfKinAddress}
          </div>
          <div className="flex items-center gap-2 mb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>
            {data?.nextOfKinFirstName + " " + data?.nextOfKinLastName}
          </div>
          <button
            onClick={() => setEdit(!edit)}
            className="text-green font-bold"
          >
            Edit
          </button>
        </div>
      ) : (
        <div className="bg-white rounded-[10px] py-[24px] px-[16px]">
          <form onSubmit={handleSubmit} className="max-w-[600px] mx-auto">
            <div className="flex gap-3">
              <div className="w-6/12 mb-3">
                <label
                  htmlFor="nextOfKinFirstName"
                  className="text-[#14141480]"
                >
                  Next of Kin First Name
                </label>
                <input
                  name="nextOfKinFirstName"
                  type="text"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                  placeholder="First Name"
                  disabled={isUpdating}
                  value={payload?.nextOfKinFirstName}
                  onChange={handleChange}
                />
              </div>
              <div className="w-6/12">
                <label htmlFor="nextOfKinLastName" className="text-[#14141480]">
                  Next of Kin Last Name
                </label>
                <input
                  name="nextOfKinLastName"
                  type="text"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2"
                  placeholder="Last Name"
                  disabled={isUpdating}
                  value={payload?.nextOfKinLastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="">
              <label htmlFor="nextOfKinAddress" className="text-[#14141480]">
                Next of Kin Address
              </label>
              <textarea
                name="nextOfKinAddress"
                className="w-full p-3 h-[150px] rounded-md border-[1px] border-[#00000033] mt-2 resize-none"
                placeholder="Address"
                value={payload?.nextOfKinAddress}
                onChange={handleChange}
              />
            </div>
            <div className="mt-[20px] flex justify-between">
              <button
                onClick={() => setEdit(!edit)}
                className="p-1 rounded-full bg-white text-green border-[1px] border-green px-6 py-2"
              >
                Close
              </button>

              <button
                type="submit"
                disabled={
                  !payload.nextOfKinFirstName ||
                  !payload.nextOfKinLastName ||
                  !payload?.nextOfKinAddress ||
                  isUpdating
                }
                className="bg-green text-white px-[35px] py-[10px] rounded-[100px]"
              >
                {isUpdating ? (
                  <Spin indicator={<LoadingOutlined spin />} />
                ) : (
                  "Update Details"
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default NextofKinPreview;
