import { Empty } from "antd";
import NairaSign from "../../../../common/NairaSign";

const RepaymentDataDisplay = ({ data }: any) => {
  return (
    <div className="p-3 bg-white rounded-md">
      <h2 className="font-semibold text-[18px] text-gray-800 mb-2">
        Repayment History
      </h2>

      {data.length > 0 ? (
        <div>
          {data.map((item: any, index: number) => (
            <div key={item.id} className="mb-4 p-4 border rounded shadow-sm">
              <strong className="text-green">Month {index + 1}</strong>
              <div>
                <strong>Amount:</strong>{" "}
                <span className="text-gray-500">
                  <NairaSign />
                  {item.amount.toLocaleString()}
                </span>
              </div>
              <div>
                <strong>Payment Date:</strong>{" "}
                <span className="text-gray-500">
                  {new Date(item.paymentDate).toLocaleDateString()}
                </span>
              </div>
              <div>
                <strong>Created At:</strong>{" "}
                <span className="text-gray-500">
                  {new Date(item.createdAt).toLocaleDateString()}{" "}
                  {new Date(item.createdAt).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </span>
              </div>
              {/* <p>
                <strong>Updated At:</strong>{" "}
                {new Date(item.updatedAt).toLocaleDateString()}{" "}
                {new Date(item.updatedAt).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </p> */}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center my-[80px] text-body-text">
          <Empty description="No Repayment Data Available" />
        </div>
      )}
    </div>
  );
};

export default RepaymentDataDisplay;
