import { useMutation } from "react-query";
import { message } from "antd";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";
import { apiConfig } from "../../../apiConfig";
import { queryClient } from "../../../services/queryClient";
import { useNavigate } from "react-router-dom";

interface Props {
  tag: string;
  amount: string;
  paymentMethod: string;
  entryReceipt: string;
  bank: string;
  accountNumber: string;
  transactionDate: string;
}

export const useWithrawSpecialSavings = (memberId: any) => {
  const token = getToken();

  const navigate = useNavigate();

  return useMutation(
    (payload: any) =>
      axiosInstance
        .post(
          `${apiConfig.ENTRIES}/${memberId}/withdrawal/special-savings`,
          { ...payload, amount: +payload?.amount * 100 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: any) => res.data),
    {
      onSuccess: (data: any) => {
        navigate(-1);
        message.success(data?.message);
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
      },
    }
  );
};
