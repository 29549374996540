import React from "react";
import NairaSign from "../../../../common/NairaSign";
import { useGetTransactionsStats } from "../../../../hooks/api/BookKeeping/useGetTransactionsStats";
import { useGetTransactionsSummary } from "../../../../hooks/api/BookKeeping/useGetTransactionsSummary";
import KoboConverter from "../../../../common/KoboConverter";

const BookKeepingStats = () => {
  const commaformat = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const { data: stats, isLoading } = useGetTransactionsStats();

  const { data } = useGetTransactionsSummary();

  const renderSkeleton = () => (
    <div className="animate-pulse flex flex-col gap-[10px] mb-[20px]">
      <div className="h-6 bg-gray-300 rounded w-3/4"></div>
      <div className="h-6 bg-gray-300 rounded w-1/2"></div>
      <div className="h-5 bg-gray-300 rounded w-1/4 mt-3"></div>
      <div className="flex justify-between gap-4 mt-2">
        <div className="h-[5px] rounded-md bg-gray-300 w-1/3"></div>
        <div className="h-[5px] rounded-md bg-gray-300 w-1/3"></div>
        <div className="h-[5px] rounded-md bg-gray-300 w-1/3"></div>
      </div>
      <div className="flex gap-4 mt-2">
        <div className="h-4 bg-gray-300 rounded-full w-16"></div>
        <div className="h-4 bg-gray-300 rounded-full w-16"></div>
        <div className="h-4 bg-gray-300 rounded-full w-16"></div>
      </div>
    </div>
  );

  const overall =
    data?.data?.totalIncome +
    data?.data?.totalExpenses +
    data?.data?.totalTaxes;

  const percentageIncome = (data?.data?.totalIncome / overall) * 100;
  const percentageExpenses = (data?.data?.totalExpenses / overall) * 100;
  const percentageTaxes = (data?.data?.totalTaxes / overall) * 100;

  return (
    <div className="flex flex-col lg:flex-row gap-[10px] mb-[20px]">
      <section className="w-full lg:w-4/12 card">
        {isLoading ? (
          renderSkeleton()
        ) : (
          <>
            <div className="text-xl font-semibold text-center">
              <KoboConverter amount={data?.data?.totalTransactions} />
            </div>
            <div className="text-center text-gray-600">Total Transactions</div>
            {overall > 0 && (
              <>
                <div className="flex gap-[5px] mt-3">
                  <div
                    className="h-[5px] rounded-md p-1 bg-green"
                    style={{ width: percentageIncome.toFixed() + "%" }}
                  ></div>
                  <div
                    className="h-[5px] rounded-md p-1 bg-greenish"
                    style={{ width: percentageExpenses.toFixed() + "%" }}
                  ></div>
                  <div
                    className="h-[5px] rounded-md p-1 bg-[#CCF6F0]"
                    style={{ width: percentageTaxes.toFixed() + "%" }}
                  ></div>
                </div>
                <div className="flex justify-center mt-2">
                  <div className="flex items-center mr-4">
                    <div className="h-[10px] w-[10px] rounded-full bg-green mr-1"></div>
                    <span className="text-sm text-gray-600">
                      Income ({percentageIncome.toFixed() + "%"})
                    </span>
                  </div>
                  <div className="flex items-center mr-4">
                    <div className="h-[10px] w-[10px] rounded-full bg-greenish mr-1"></div>
                    <span className="text-sm text-gray-600">
                      Expenses ({percentageExpenses.toFixed() + "%"})
                    </span>
                  </div>
                  <div className="flex items-center">
                    <div className="h-[10px] w-[10px] rounded-full bg-[#CCF6F0] mr-1"></div>
                    <span className="text-sm text-gray-600">
                      Taxes ({percentageTaxes.toFixed() + "%"})
                    </span>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </section>
      <section className="w-full lg:w-8/12 ">
        {isLoading && (
          <div className="flex flex-col md:flex-row gap-[10px]">
            <>
              <div className="w-full md:w-4/12 card">{renderSkeleton()}</div>
              <div className="w-full md:w-4/12 card">{renderSkeleton()}</div>
              <div className="w-full md:w-4/12 card">{renderSkeleton()}</div>
            </>
          </div>
        )}
        {!isLoading && data && (
          <div className="grid grid-cols-3 gap-2  ">
            <div className="bg-white rounded-md p-3 pb-[50px]">
              <p className="mt-[20px] text-gray-600">Total Income</p>
              <div className="text-xl font-semibold mt-3">
                <KoboConverter amount={data?.data?.totalIncome} />
              </div>
            </div>
            <div className="bg-white rounded-md p-3 pb-[50px]">
              <p className="mt-[20px] text-gray-600">Total Expenses</p>
              <div className="text-xl font-semibold mt-3">
                <KoboConverter amount={data?.data?.totalExpenses} />
              </div>
            </div>
            <div className="bg-white rounded-md p-3 pb-[50px]">
              <p className="mt-[20px] text-gray-600">Total Taxes</p>
              <div className="text-xl font-semibold mt-3">
                <KoboConverter amount={data?.data?.totalTaxes} />
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default BookKeepingStats;
