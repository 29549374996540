import React, { ChangeEvent, useState } from "react";
import AppLayout from "../AppLayout";
import Back from "../../../common/Back";
import Card from "../../../common/Card";
import { Divider, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { getToken } from "../../../utils/getToken";
import { useCreateTransaction } from "../../../hooks/api/BookKeeping/useCreateTransaction";
import ImagePreviewer from "../../../common/ImagePreviewer";

const CreateTransaction = () => {
  const tags = ["expense", "income", "tax"];
  const [, setFile] = useState<File | null>(null);
  const token = getToken();

  const [payload, setPayload] = useState({
    tag: "",
    description: "",
    amount: "",
    bankCharge: "",
    note: "",
    paymentMethod: "",
    bankName: "",
    recipientName: "",
    accountNumber: "",
    receiptUrl: "",
    transactionDate: "",
  });

  const formatNumberWithCommas = (value: string) => {
    const numericValue = value.replace(/\D/g, "");
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setPayload((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/,/g, "");
    setPayload((prev) => ({ ...prev, [name]: numericValue }));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = new Date(e.target.value).toISOString();
    setPayload((prev) => ({ ...prev, transactionDate: formattedDate }));
  };

  const handleUpload = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("files", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_COTRACKR_BASE_URL}/misc/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("File uploaded successfully!");
      setPayload((prev) => ({ ...prev, receiptUrl: response.data.data }));
      console.log(response.data);
    } catch (error) {
      message.error("File upload failed!");
      console.error(error);
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile) {
      await handleUpload(selectedFile);
    }
  };
  const activeButton = "bg-green text-white";
  const inActiveButton =
    "bg-white text-black border-[1px] border-[#00000033] text-[14px]";

  const { mutateAsync, isLoading } = useCreateTransaction();

  const handleSubmit = async () => {
    mutateAsync(payload);
  };

  return (
    <AppLayout title="Book Keeping">
      <Card>
        <div className="mb-4">
          <Back />
          <h3 className="text-xl my-3">Record Transaction</h3>
          <div className="grid gap-2 grid-cols-3 pb-6">
            {tags.map((tag) => (
              <button
                key={tag}
                className={`capitalize p-[10px] rounded-md min-w-[150px] scrollbar-hide ${
                  payload?.tag === tag ? activeButton : inActiveButton
                }`}
                onClick={() => {
                  setPayload({ ...payload, tag: tag });
                }}
              >
                {tag}
              </button>
            ))}
          </div>

          {payload.tag && (
            <>
              <div className="rounded-md bg-gray-200 p-2 mb-5 transition-opacity duration-500 ease-in-out">
                <div className="opacity-100">
                  <h4 className="text-lg font-semibold capitalize">
                    {payload.tag}
                  </h4>
                  <p className="text-sm text-gray-600">
                    {payload.tag === "expense" && "Record your expenses here."}
                    {payload.tag === "income" && "Record your income here."}
                    {payload.tag === "tax" && "Record your taxes here."}
                  </p>
                </div>
              </div>

              <div className="flex gap-2 mb-[20px]">
                <div className="w-6/12">
                  <label
                    className="text-[#14141480] mt-2"
                    htmlFor="paymentMethod"
                  >
                    Payment Method
                  </label>
                  <select
                    name="paymentMethod"
                    className="p-[12px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                    onChange={handleChange}
                    value={payload.paymentMethod}
                  >
                    <option value="">None</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                    <option value="Cash">Cash</option>
                  </select>
                </div>
                <div className="w-6/12">
                  <label className="text-[#14141480] mt-2" htmlFor="amount">
                    Amount
                  </label>
                  <input
                    name="amount"
                    type="text"
                    className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                    placeholder="Amount"
                    value={formatNumberWithCommas(payload.amount)}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex gap-2 mb-[20px]">
                <div className="w-6/12">
                  <label
                    className="text-[#14141480] mt-2"
                    htmlFor="accountNumber"
                  >
                    Account Number
                  </label>
                  <input
                    name="accountNumber"
                    type="number"
                    className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                    placeholder="Enter account number"
                    value={payload.accountNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-6/12">
                  <label className="text-[#14141480] mt-2" htmlFor="bank">
                    Bank
                  </label>
                  <input
                    name="bankName"
                    type="text"
                    className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                    placeholder="Enter bank name"
                    value={payload.bankName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex gap-2 mb-[20px]">
                <div className="w-6/12">
                  <label
                    className="text-[#14141480] mt-2"
                    htmlFor="recipientName"
                  >
                    Recepient's Name
                  </label>
                  <input
                    name="recipientName"
                    type="text"
                    className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                    placeholder="Recepient's Name"
                    value={payload.recipientName}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-6/12">
                  <label className="text-[#14141480] mt-2" htmlFor="bankCharge">
                    Bank Charge (optional)
                  </label>
                  <input
                    name="bankCharge"
                    type="text"
                    className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                    placeholder="Enter bank charge"
                    value={formatNumberWithCommas(payload.bankCharge)}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex gap-2 mb-[20px]">
                <div className="w-6/12">
                  <label
                    className="text-[#14141480] mt-2"
                    htmlFor="transactionDate"
                  >
                    Transaction Date
                  </label>
                  <input
                    id="transactionDate"
                    name="transactionDate"
                    type="date"
                    className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
                    onChange={handleDateChange}
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div className="w-6/12">
                  <label
                    className="text-[#14141480] block mb-1"
                    htmlFor="transactionDate"
                  >
                    Receipt
                  </label>
                  <div>
                    <ImagePreviewer
                      text="Upload Receipt"
                      modalTitle="Reciept Preview"
                      prop={payload?.receiptUrl}
                      handleFileChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>

              <Divider />
              <div className="mt-[20px]">
                <label htmlFor="description" className="text-[#14141480]">
                  Description
                </label>
                <textarea
                  name="description"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2 resize-none"
                  placeholder="Enter description here"
                  value={payload?.description}
                  onChange={handleChange}
                />
              </div>
              <div className="mt-[20px]">
                <label htmlFor="note" className="text-[#14141480]">
                  Note (optional)
                </label>
                <textarea
                  name="note"
                  className="w-full p-3 rounded-md border-[1px] border-[#00000033] mt-2 resize-none"
                  placeholder="Enter note here"
                  value={payload?.note}
                  onChange={handleChange}
                />
              </div>
              <button
                disabled={
                  Object.entries(payload).some(
                    ([key, value]) =>
                      key !== "note" && key !== "bankCharge" && !value
                  ) || isLoading
                }
                className={`bg-green rounded-full p-[24px] font-bold text-white w-full mt-[16px] ${
                  isLoading ? "cursor-not-allowed opacity-50" : ""
                }`}
                type="submit"
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <Spin
                    className="text-white"
                    size={"large"}
                    indicator={<LoadingOutlined spin />}
                  />
                ) : (
                  "Proceed"
                )}
              </button>
            </>
          )}
        </div>
      </Card>
    </AppLayout>
  );
};

export default CreateTransaction;
