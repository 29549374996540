import React, { useState } from "react";
import AppLayout from "../AppLayout";
import { useParams, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { Table, Button } from "antd";
import KoboConverter from "../../../common/KoboConverter";
import { useGetEntriesQuery } from "../../../hooks/api/Entries/useGetEntriesQuery";
import { useGetMemberEntries } from "../../../hooks/api/Entries/useGetMemberEntries";

const MemberActivities = () => {
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const { data: memberEntries } = useGetMemberEntries({ id, page });
  const navigate = useNavigate();
  const { data: entries, isFetching: isFetchingEntries } = useGetEntriesQuery({
    search: "",
    tag: "",
    isDeleted: false,
    startDate: "",
    endDate: "",
  });

  const goBack = () => {
    navigate(-1);
  };

  const getTagLabel = (tag: string) => {
    switch (tag) {
      case "repayment":
        return "Repayment";
      case "shares":
        return "Shares";
      case "savings":
        return "Savings";
      case "special_savings":
        return "Special Savings";
      case "monthly_dues":
        return "Monthly Dues";
      case "other_savings":
        return "Other Savings";
      case "withdrawal":
        return "Withdrawal";
      case "development":
        return "Development";
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      render: (tag: string) => <>{getTagLabel(tag)}</>,
    },
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
      render: (text: string) => text || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: string) => <KoboConverter amount={amount} />,
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
        </div>
      ),
    },
    {
      title: "Recorded Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <div>
          <Moment format="DD-MM-YYYY">{date}</Moment>
        </div>
      ),
    },
    {
      title: "Receipt",
      dataIndex: "entryReceipt",
      key: "entryReceipt",
      render: (entryReceipt: string) => (
        <a
          href={entryReceipt}
          target="_blank"
          rel="noreferrer"
          className="text-green"
        >
          View Receipt
        </a>
      ),
    },
  ];

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const paginationConfig = {
    total: memberEntries?.data?.meta?.totalDocs || 0,
    current: memberEntries?.data?.meta?.page || 1,
    pageSize: memberEntries?.data?.meta?.limit || 10,
    onChange: handlePageChange,
  };

  return (
    <AppLayout title="Member Activities">
      <div className="mb-3">
        <Button
          onClick={goBack}
          className="flex gap-1 items-center bg-gray-200 rounded-md p-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
            />
          </svg>
          Back
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={memberEntries?.data?.docs}
        loading={isFetchingEntries}
        pagination={paginationConfig}
        scroll={{ y: "60vh" }}
        rowKey="id"
      />
    </AppLayout>
  );
};

export default MemberActivities;
