import KoboConverter from "../../../common/KoboConverter";
import { useGetGrossDividends } from "../../../hooks/api/SharesAndDividends/Dividends/useGetGrossDividends";
import { useGetGrossExpenses } from "../../../hooks/api/SharesAndDividends/Dividends/useGetGrossExpenses";
import { useGetTotalShares } from "../../../hooks/api/SharesAndDividends/Shares/useGetTotalShares";
import AppLayout from "../AppLayout";

const SharedAndDividends = () => {
  const { data: dividends } = useGetGrossDividends();
  const { data: expenses } = useGetGrossExpenses();
  // const { data: shares } = useGetTotalShares();
  // const { data: totalExpenses } = useGetTotalExpenses();
  return (
    <AppLayout title="Shares & Dividends">
      {/* <p>dividends = {JSON.stringify(dividends?.data)}</p> */}
      <h3 className="font-bold">Dividends</h3>
      <div className="grid grid-cols-3 gap-4 mb-5">
        <div className="p-3 rounded-md bg-white">
          <h3>Total Loan Interest</h3>
          <span className="text-gray-600">
            {dividends?.data?.totalLoanInterest ? (
              <KoboConverter amount={dividends?.data?.totalLoanInterest} />
            ) : (
              0
            )}
          </span>
        </div>
        <div className="p-3 rounded-md bg-white">
          <h3>Total Shares Income</h3>
          <span className="text-gray-600">
            {dividends?.data?.totalSharesIncome ? (
              <KoboConverter amount={dividends?.data?.totalSharesIncome} />
            ) : (
              0
            )}
          </span>
        </div>
        <div className="p-3 rounded-md bg-white">
          <h3>Total Dividends</h3>
          <span className="text-gray-600">
            {dividends?.data?.totalDividend ? (
              <KoboConverter amount={dividends?.data?.totalDividend} />
            ) : (
              0
            )}
          </span>
        </div>
      </div>
      <h3 className="font-bold">Expenses</h3>
      <div className="grid grid-cols-4 gap-4 mt-2">
        <div className="p-3 rounded-md bg-white">
          <h3>Total Amount</h3>
          <span className="text-gray-600">
            {expenses?.data[0]?.totalAmount ? (
              <KoboConverter amount={expenses?.data[0]?.totalAmount} />
            ) : (
              0
            )}
          </span>
        </div>
        <div className="p-3 rounded-md bg-white">
          <h3>Total Bank Charges</h3>
          <span className="text-gray-600">
            {expenses?.data[0]?.totalBankCharges ? (
              <KoboConverter amount={expenses?.data[0]?.totalBankCharges} />
            ) : (
              0
            )}
          </span>
        </div>
        <div className="p-3 rounded-md bg-white">
          <h3>Total Balance</h3>
          <span className="text-gray-600">
            {expenses?.data[0]?.totalBalance ? (
              <KoboConverter amount={expenses?.data[0]?.totalBalance} />
            ) : (
              0
            )}
          </span>
        </div>
        <div className="p-3 rounded-md bg-white">
          <h3>Difference</h3>
          <span className="text-gray-600">
            {expenses?.data[0]?.difference ? (
              <KoboConverter amount={expenses?.data[0]?.difference} />
            ) : (
              0
            )}
          </span>
        </div>
      </div>
    </AppLayout>
  );
};

export default SharedAndDividends;
