import React from "react";
import MemberDeposits from "./MemberActions/MemberDeposits";
import AppLayout from "../AppLayout";
import Card from "../../../common/Card";

const MemberDeposit = () => {
  return (
    <AppLayout
      title="Member Deposit"
      // crumb={`${data?.data?.firstName + " " + data?.data?.lastName}`}
    >
      <Card>
        <MemberDeposits />
      </Card>
    </AppLayout>
  );
};

export default MemberDeposit;
