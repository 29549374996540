import { useQuery } from "react-query";
import { message } from "antd";
import { getToken } from "../../../utils/getToken";
import { axiosInstance } from "../../../axiosInstance";
import { apiConfig } from "../../../apiConfig";

export const useGetEntriesQuery = ({
  search,
  tag,
  page,
  isDeleted,
  startDate,
  endDate,
}: {
  search?: string;
  tag?: string;
  page?: number;
  isDeleted?: boolean;
  startDate?: string;
  endDate?: string;
}) => {
  const token = getToken();

  const queryParams = new URLSearchParams();
  if (search) queryParams.append("search", search);
  if (tag) queryParams.append("tag", tag);
  if (isDeleted) queryParams.append("isDeleted", `${isDeleted}`);
  if (page) queryParams.append("page", `${page}`);
  if (startDate) queryParams.append("startDate", startDate);
  if (endDate) queryParams.append("endDate", endDate);

  return useQuery({
    queryKey: [
      "useGetEntriesQuery",
      search,
      tag,
      isDeleted,
      startDate,
      endDate,
    ],
    queryFn: () =>
      axiosInstance
        .get(`${apiConfig.ENTRIES}?${queryParams.toString()}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => res.data),
    onSuccess: (data) => {},
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
    },
    enabled: !!token,
  });
};
