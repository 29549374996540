import React, { ChangeEvent, FormEvent, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useUpdateUserPreference } from "../../../hooks/api/Profile/useUpdateUserPreference";
import { useUploadFile } from "../../../hooks/api/Misc/useUploadFile";
import ImageUpload from "./ImageUpload";

const CompleteRegistration = ({ setOpen }: { setOpen: any }) => {
  const Image = require("../../../assets/images/CompleteRegistration.png");

  const [payload, setPayload] = useState({
    profileImage: "",
    societyName: "",
    interestRate: "",
    memberSize: "",
  });

  const { mutateAsync, isLoading } = useUpdateUserPreference();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutateAsync(payload, {
      onSuccess: () => {
        setOpen(false);
      },
    });
  };

  const {
    mutateAsync: uploadFile,
    isLoading: isUploading,
    data,
  } = useUploadFile();

  const handleFileChange = async (file: File) => {
    try {
      const result = await uploadFile(file);
      await setPayload({ ...payload, profileImage: result?.data });
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    const numericValue = value.replace(/,/g, "");

    setPayload({
      ...payload,
      [name]: numericValue,
    });
  };

  const handleSuccess = (data: any) => {
    console.log("Uploaded image data:", data);
    setPayload({ ...payload, profileImage: data?.data });
  };

  const hasPayloadValues = Object.values(payload).some((value) => value !== "");

  return (
    <div className="md:flex block overflow-hidden h-[90vh]">
      <div className="md:w-6/12 w-full overflow-y-scroll">
        <div className="max-w-[400px] mx-auto">
          <div className="mt-8">
            <h3 className="text-[24px] mb-[5px]">Complete registration</h3>
            <p className="mb-[50px]">Almost done. Just a few steps to go!</p>

            {hasPayloadValues && (
              <button
                className="text-[#3d3d3d] bg-[#ddd] px-4 py-2 rounded-full"
                onClick={() =>
                  setPayload({
                    profileImage: "",
                    societyName: "",
                    interestRate: "",
                    memberSize: "",
                  })
                }
              >
                Reset Form
              </button>
            )}

            <form className="mt-[20px]" onSubmit={handleSubmit}>
              {!payload?.profileImage ? (
                <ImageUpload onSuccess={handleSuccess} />
              ) : (
                <img
                  src={payload?.profileImage}
                  className="h-[100px] w-[100px] rounded-full"
                  alt=""
                />
              )}
              <h3 className="text-[14px] mb-2 mt-4">Society Name</h3>
              <input
                type="text"
                name="societyName"
                placeholder="Society Name"
                autoCorrect="false"
                value={payload?.societyName}
                disabled={isLoading}
                onChange={handleChange}
                className="w-full p-3 rounded-md border-[1px] border-[#00000033]"
              />
              <h3 className="text-[14px] mb-2 mt-4">Select Preferences</h3>
              <select
                name="memberSize"
                disabled={isLoading}
                onChange={handleChange}
                value={payload?.memberSize}
                className="w-full p-3 rounded-md border-[1px] border-[#00000033]"
              >
                <option value="" disabled>
                  Select option
                </option>
                <option value="1-500">
                  Small Scale Co-operative (1-500 Members)
                </option>
                <option value="501-1000">
                  Medium Scale Co-operative (501-1000 Members)
                </option>
                <option value="1000 and above">
                  Large Scale Co-operative (above 1000 Members)
                </option>
              </select>
              <h3 className="text-[14px] mb-2 mt-4">Interest Rate</h3>
              <select
                id=""
                name="interestRate"
                value={payload.interestRate}
                onChange={handleInputChange}
                className="w-full p-3 rounded-md border-[1px] border-[#00000033]"
              >
                <option value="" disabled>
                  Select option
                </option>
                <option value="1">1%</option>
                <option value="1.5">1.5%</option>
                <option value="2">2%</option>
                <option value="2.5">2.5%</option>
                <option value="3">3%</option>
                <option value="3.5">3.5%</option>
                <option value="4">4%</option>
              </select>
              <button
                disabled={
                  !payload.memberSize ||
                  !payload.interestRate ||
                  !payload.societyName ||
                  !payload?.profileImage
                }
                className={`bg-green rounded-full p-[12px] font-bold text-white w-full mb-[40px] mt-[16px]
             ${
               !payload.memberSize ||
               !payload.interestRate ||
               !payload.societyName ||
               !payload?.profileImage
                 ? "cursor-not-allowed opacity-70"
                 : ""
             }`}
                type="submit"
              >
                {isLoading ? (
                  <Spin
                    className="text-white"
                    size={"large"}
                    indicator={<LoadingOutlined spin />}
                  />
                ) : (
                  "Proceed"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div
        className="md:w-6/12 md:block hidden h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${Image})` }}
      ></div>
    </div>
  );
};

export default CompleteRegistration;
