import { useState } from "react";
import { formatNumberWithCommas } from "../../../../utils/formatNumberWithCommas";
import ImageUpload from "../../CompleteRegistration/ImageUpload";
import { useMakeLoanRepayment } from "../../../../hooks/api/Loans/useMakeLoanRepayment";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const RepaymentForm = () => {
  const { id } = useParams();
  const [repayment, setRepayment] = useState({
    tag: "repayment",
    amount: "",
    paymentMethod: "",
    bank: "",
    transactionDate: "",
    entryReceipt: "",
    accountNumber: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "transactionDate") {
      const utcDate = new Date(value).toISOString();
      setRepayment((prev) => ({ ...prev, [name]: utcDate }));
    } else {
      const numericValue = value.replace(/,/g, "");
      setRepayment((prev) => ({ ...prev, [name]: numericValue }));
    }
  };

  const handleSuccess = (data: any) => {
    setRepayment({ ...repayment, entryReceipt: data?.data });
  };

  const { mutateAsync, isLoading } = useMakeLoanRepayment(`${id}`);

  const handleSubmit = () => {
    mutateAsync(repayment);
  };

  return (
    <div className="flex mt-5">
      <div className="w-3/12 font-bold">Repayment</div>
      <div className="w-9/12">
        <div className="flex space-1 gap-2">
          <div className="w-6/12">
            <label className="text-body-text mt-4 mb-1 block" htmlFor="bank">
              Amount
            </label>
            <input
              type="text"
              name="amount"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mb-2"
              placeholder="Enter amount"
              value={formatNumberWithCommas(`${repayment.amount}`)}
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          <div className="w-6/12">
            <label className="text-body-text mt-4 mb-1 block" htmlFor="bank">
              Upload Receipt
            </label>
            <ImageUpload onSuccess={handleSuccess} />
          </div>
        </div>
        <div className="flex space-1 gap-2">
          <div className="w-6/12">
            <label
              className="text-body-text mt-4 block"
              htmlFor="paymentMethod"
            >
              Payment Method
            </label>
            <select
              id="paymentMethod"
              name="paymentMethod"
              className="p-3 rounded-md w-full border-[1px] border-[#00000033] mt-1"
              onChange={handleInputChange}
              value={repayment.paymentMethod}
            >
              <option value="">None</option>
              <option value="Bank Transfer">Bank Transfer</option>
              <option value="Cash">Cash</option>
            </select>
          </div>
          <div className="w-6/12">
            <label
              className="text-body-text mt-4 block"
              htmlFor="transactionDate"
            >
              Transaction Date
            </label>
            <input
              id="transactionDate"
              name="transactionDate"
              type="date"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
              onChange={handleInputChange}
              value={repayment.transactionDate.split("T")[0]}
              max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        <div className="flex gap-2 mb-[20px]">
          <div className="w-6/12">
            <label
              className="text-body-text mt-4 block"
              htmlFor="accountNumber"
            >
              Account Number
            </label>
            <input
              id="accountNumber"
              name="accountNumber"
              type="text"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
              placeholder="Enter account number"
              value={repayment.accountNumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-6/12">
            <label className="text-body-text mt-4 block" htmlFor="bank">
              Bank
            </label>
            <input
              id="bank"
              name="bank"
              type="text"
              className="p-[10px] rounded-md w-full border-[1px] border-[#00000033] mt-1"
              placeholder="Enter bank name"
              value={repayment.bank}
              onChange={handleInputChange}
            />
          </div>
        </div>
        {JSON.stringify(repayment)}
        <div className="mt-[20px] flex justify-end">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={` text-white px-[35px] py-[10px] rounded-[100px] ${
              isLoading ? "bg-green/50" : "bg-green"
            }`}
          >
            {isLoading ? (
              <LoadingOutlined style={{ fontSize: 16, color: "#fff" }} spin />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RepaymentForm;
