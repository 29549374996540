import { Link } from "react-router-dom";

type Props = {
  title: string;
  description: string;
  route: string;
  linkTitle: string;
  right?: React.ReactNode;
};

const TableHeader = ({
  title,
  description,
  route,
  linkTitle,
  right,
}: Props) => {
  return (
    <div className="flex items-center justify-between mb-[20px] ">
      <div>
        <h3 className="text-black text-[16px]">{title}</h3>
        <p className="text-[14px] text-gray-600">{description}</p>
      </div>
      <div className="flex gap-2 items-center">
        {right}
        <Link
          to={route}
          className="text-green rounded-full px-[20px] py-[10px] border-[1px] border-green text-[13px]"
        >
          {linkTitle}
        </Link>
      </div>
    </div>
  );
};

export default TableHeader;
