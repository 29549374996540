import AppLayout from "../AppLayout";
import Back from "../../../common/Back";
import AddMemberForm from "./Forms/AddMemberForm";

const AddMember = () => {
  return (
    <AppLayout title="Create New Member">
      <Back />
      <AddMemberForm />
    </AppLayout>
  );
};

export default AddMember;
