import { message, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";

const ImagePreviewer = ({ prop, handleFileChange, text, modalTitle }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div>
      {prop !== "" ? (
        <div className="mt-1 p-2 rounded-md border">
          <div className="flex gap-2 items-center">
            <img src={prop} alt="Receipt" style={{ height: "30px" }} />
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <button onClick={() => setIsModalVisible(true)}>
                  Preview Image
                </button>
                {/* <button onClick={() => message.info("clicked")}>close</button> */}
              </div>
            </div>
          </div>
          <Modal
            title={modalTitle}
            open={isModalVisible}
            footer={null}
            onCancel={() => setIsModalVisible(false)}
          >
            <img src={prop} alt="Receipt" style={{ width: "100%" }} />
          </Modal>
        </div>
      ) : (
        <label
          className="inline-flex w-full items-center gap-2 p-[10px] text-gray-800 rounded-md bg-[#00000033] cursor-pointer
        hover:opacity-50 transition-all duration-300 ease-in-out"
        >
          <UploadOutlined />
          <span>{text}</span>
          <input
            type="file"
            multiple={false}
            onChange={handleFileChange}
            className="hidden"
          />
        </label>
      )}
    </div>
  );
};

export default ImagePreviewer;
